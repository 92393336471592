import Cookies from './Cookie';

/**
 * Cookie Storage Helper
 */
class CookieStorageHelper {

	static set(key, value, options = {}) {
		Cookies.set(key, value, { expires: options.expires || 360 });
	}

	/**
	 *
	 * @param key
	 * @param req - The ctx.req from the server. If it is provided we'll attempt to get the value from the request cookies
	 */
	static async get(key, req = null) {
		return Cookies.getJSON(key, req) || null;
	}

	static async keys() {
		return [];
	}

	static remove(key) {
		Cookies.remove(key);
	}

	static clear() {
		Cookies.clear();
	}

	static decode(value) {
		try {
			return JSON.parse(value);
		} catch(e) {
			return value;
		}
	}

}

export default CookieStorageHelper;
