<template>
	<no-ssr>
		<base-slide-up-down v-bind="$attrs"><slot></slot></base-slide-up-down>
	</no-ssr>
</template>

<script>

import SlideUpDown from 'vue-slide-up-down';

export default {

	components: {
		BaseSlideUpDown: SlideUpDown,
	},

};

</script>
