<template>
	<span :title="date">{{ formattedDate() }}</span>
</template>

<script>

import moment from 'moment-timezone';

/**
 * Date Component - For displaying friendly dates to the user
 */
export default {

	props: {
		date: {
			required: true,
		},
		withTime: {
			type: Boolean,
			default: true,
		},
		format: {
			type: String,
			default: null,
		},
		/**
		 * The timezone the provided date is in. Database dates are generally UTC
		 */
		sourceTimezone: {
			type: String,
			default: 'UTC',
		},
	},

	data() {
		return {
			interval: 60,
		};
	},

	methods: {

		/**
		 * The relative formatted date
		 */
		formattedDate() {
			const momentDate = moment.tz(this.date, this.sourceTimezone).tz(moment.tz.guess());

			if (!momentDate.isValid()) {
				return '';
			}

			let format = this.format || 'MMM D, YYYY';
			if (!this.format && this.withTime) {
				format += ' @ h:mmA z';
			}

			return momentDate.format(format);

		},
	},
};
</script>
