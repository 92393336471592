<template>
	<span :title="date">{{ formattedDate() }}</span>
</template>

<script>

import moment from 'moment-timezone';

/**
 * Date Component - For displaying friendly dates to the user
 */
export default {

	props: {
		date: {
			type: String,
			required: true,
		},
		/**
		 * The timezone the provided date is in. Database dates are generally UTC
		 */
		sourceTimezone: {
			type: String,
			default: 'UTC',
		},
	},

	data() {
		return {
			intRef: null,
			interval: 60,
		};
	},

	mounted() {

		moment.updateLocale('en', {
			relativeTime : {
				future: "in %s",
				past:   "%s ago",
				s  : 'a moment',
				ss : '%d secs',
				m:  "1 min",
				mm: "%d mins",
				h:  "an hour",
				hh: "%d hrs",
				d:  "1 day",
				dd: "%d days",
				M:  "1 month",
				MM: "%d months",
				y:  "1 year",
				yy: "%d years"
			}
		});

		this.startRenderCycle();
	},

	beforeDestroy() {
		this.killRenderCycle();
	},

	methods: {

		/**
		 * The relative formatted date
		 */
		formattedDate() {
			const momentDate = moment.tz(this.date, this.sourceTimezone).tz(moment.tz.guess());

			if (!momentDate.isValid()) {
				return '';
			}

			if (moment().subtract(14, 'day').isSameOrAfter(momentDate)) {
				return momentDate.format('MMM D, YYYY');
			}

			if (Math.abs(moment.duration(moment().diff(momentDate)).asSeconds()) < 10) {
				return 'just now';
			}

			return momentDate.fromNow();
		},

		/**
		 * Return interval in SECONDS (not ms)
		 * @returns {number}
		 */
		getInterval() {
			const diff = Math.abs(moment().diff(moment.tz(this.date, this.sourceTimezone), 'minutes'), true);
			if (diff < 1) {
				return 2;
			}
			else if (diff < 15) {
				return 4;
			}
			else if (diff < 60) {
				return 60;
			}

			return 60 * 10;
		},

		/**
		 * Starts the cycle to force re-rendering this component
		 */
		startRenderCycle() {
			this.interval = this.getInterval();
			this.intRef = setInterval(() => {
				const checkInterval = this.getInterval();

				if (this.interval !== checkInterval) {
					// This post is older, don't need to force render so often
					clearInterval(this.intRef);
					this.startRenderCycle();
				}

				this.$forceUpdate();
			}, this.interval * 1000);
		},

		killRenderCycle() {
			// Unset Render Cycle
			clearInterval(this.intRef);
			this.intRef = null;
		},
	},
};
</script>
