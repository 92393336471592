import { LAYOUTS } from '../constants';
import getMeta from '@/utils/getMeta';
import getLayout from '@/utils/getLayout';
import { hasCompletedSignup } from '@/utils/verify-signup-status';

const DEFAULT_BUSINESS_PAGE = '/business/locations';
const DEFAULT_PAGE = '/browse/list';
const defaultRedirectPath = (store) => {
	if (store.getters.isBusiness) {
		return DEFAULT_BUSINESS_PAGE;
	}

	return DEFAULT_PAGE;
};

/**
 * Redirect path if user can't access route
 * @param to
 * @param store
 * @returns {null|string} - Returns path to new route if redirect is required, null if no redirect required
 */
const routeRestrictionRedirect = (to, store) => {
	const authRequired = getMeta(to, 'requiredAuth', null);
	const guestRequired = getMeta(to, 'guest', false);

	if (guestRequired && store.getters.isLoggedIn) {
		return DEFAULT_PAGE; // Cannot access this page while logged in
	}

	if (!authRequired) {
		return null; // No auth restrictions
	}

	if (!store.getters.isLoggedIn) {
		store.dispatch('setIntended', to.fullPath);
		return `/login/${ authRequired }`;
	}

	if (
		(authRequired === 'customer' && !store.getters.isCustomer) ||
		(authRequired === 'business' && !store.getters.isBusiness)
	) {
		return defaultRedirectPath(store); // Incorrect Authed User Type
	}

	if (!hasCompletedSignup(store) && !getMeta(to, 'step2')) {
		return `/signup/${ authRequired }/info`; // Step 2 Signup Details Required
	}

	return null;
};

export default function({ router, store }) {

	// Global Navigation Guards
	router.beforeEach(async (to, from, next) => {
		const redirectTo = routeRestrictionRedirect(to, store);
		if (redirectTo) {
			return next(redirectTo);
		}

		if (getLayout(to) === LAYOUTS.APP) {
			store.dispatch('setSiteLoading');
		}

		store.dispatch('onRouteChange', to);

		next();

	});

	router.afterEach(() => {
		store.dispatch('setSiteLoading', false);
		if (process.client) {
			window.scrollTo(0, 0);
		}
	});

	router.onError(() => {
		store.dispatch('setSiteLoading', false);
	});
}
