import Storage from '@/storage/Storage';

class Listener {

	constructor({ app }) {
		this.app = app;
		this.$store = app.$store;
		this.storage = Storage(app.$store.getters.platform);
	}

	subscribe() {
		this.$store.subscribe((mutation, state) => {
			switch (mutation.type) {
				/** Cart **/
				case 'ADD_ITEM':
				case 'CHANGE_QUANTITY':
				case 'REMOVE_ITEM':
					this.storage.set('cart', {
						cart: state.cart.cart,
						locationId: state.cart.locationId
					});
					break;
				case 'CLEAR_CART':
					this.storage.remove('cart');
					break;
				case 'CLEAR_COMPLETED_ORDER':
					// Intentional fallthrough - will just set completedOrders to the new array
				case 'COMPLETE_ORDER':
					this.storage.set('completedOrders', state.cart.completedOrders, {
						expires: 1
					});
					break;
				/** Auth **/
				case 'SET_USER':
					this.storage.set('user', mutation.payload, { expires: 180, preferCookies: true });
					break;
				case 'SET_AUTH_TOKEN':
					this.storage.set('userAuthToken', mutation.payload, { expires: 180, preferCookies: true });
					break;
				case 'SET_CURRENT_LOCATION':
					this.storage.set('location', mutation.payload);
					break;
				case 'CLEAR_CURRENT_LOCATION':
					this.storage.remove('location');
					break;
				case 'SET_CURRENT_ACCOUNT':
					this.storage.set('account', state.accountsById[ mutation.payload ] || {}, { expires: 180, preferCookies: true });
					break;
				case 'LOGOUT':
					this.storage.remove('user');
					this.storage.remove('userAuthToken');
					break;
				case 'SET_PENDING_INVITATION':
					this.storage.set('pendingInvitation', mutation.payload.pendingInvite, { expires: 180, preferCookies: true });
					break;
				case 'CLEAR_PENDING_INVITATION':
					this.storage.remove('pendingInvitation');
					break;
			}
		});
	}
}

export default {

	// When everything is ready to go !
	ready(context) {
		try {
			if (!process.client) return;

			// eslint-disable-next-line no-unused-vars
			const listeners = new Listener(context);
			listeners.subscribe();
		} catch(e) {
			console.log('eventListeners ready failure', e.message);
		}
	},

};
