<template>
	<div>
		<slot></slot>
	</div>
</template>

<script>

export default {

	/**
	 * @param {String} name - The name of the drawer
	 * @param {Boolean} open - The default state of the drawer (default closed)
	 */
	props: [ 'name', 'open' ],

	data() {
		return {
			isOpen: this.open,
		};
	},

	mounted() {
		this.addRootListener('open-drawer', (name, props = {}) => this.eventRequest(name, true, props));
		this.addRootListener('close-drawer', (name, props = {}) => this.eventRequest(name, false, props));
		this.addRootListener('toggle-drawer', (name, props = {}) => this.eventRequest(name, !this.isOpen, props));
	},

	methods: {

		eventRequest(name, isOpen, props) {
			if (this.name === name) {
				this.isOpen = isOpen;
			}

			this.$root.$emit(this.isOpen ? 'drawer-opened' : 'drawer-closed', name, props);
			this.$root.$emit(this.isOpen ? name + '.drawer-opened' : name + '.drawer-closed', props);
		}

	},

};

</script>
