import { Plugins, registerWebPlugin } from '@capacitor/core';
// import { SignInWithApple } from "@capacitor-community/apple-sign-in";
import 'capacitor-square-payments';
const { SquarePayment, Device, Keyboard } = Plugins;
import Storage from '@/storage/Storage';
import Hammer from 'hammerjs';

function getScript(src) {
	var s = document.createElement('script'); // Create a script element
	s.type = "text/javascript";               // optional in html5
	s.async = false;                           // asynchronous? true/false
	s.src = src;
	var fs = document.getElementsByTagName('script')[0];  // Get the first script
	fs.parentNode.insertBefore(s, fs);
}

async function init({ store }) {

	if (registerWebPlugin) {
		// registerWebPlugin(SignInWithApple);
	}

	const info = await Device.getInfo();
	const storage = Storage(info.platform);

	store.dispatch('setPlatform', info.platform);
	store.dispatch('setDeviceInfo', info);

	try {
		let pendingInvitation = await storage.get('pendingInvitation');
		if (pendingInvitation) {
			store.dispatch('loadPendingAuth', pendingInvitation);
		}
	} catch (e) {
		console.warn('Unable to load pending invite from storage');
	}

	if (info.platform !== 'web') {
		Keyboard.setAccessoryBarVisible({ isVisible: true });

		SquarePayment.initApp({
			applicationId: 'sq0idp-l5Q8vb0HjSZs4uyBlQ_tOQ',
			appleMerchantId: 'merchant.app.finegrounds.app',
			googlePayMerchantId: '451625747211593476',
		});
	}


	// Enable swiping to open the menu
	var swipe = new Hammer(document);
	swipe.on('swipeleft', (e) => {
		e.preventDefault();
		if (e.distance > 50) {
			store.dispatch('closeMenu');
		}
	});

	storage.get('intended').then(intended => {
		if (intended) {
			store.dispatch('setIntended', intended);
		}
	});

	if (info.platform === 'web') {
		getScript(process.env.NODE_ENV === 'production'
			? 'https://js.squareup.com/v2/paymentform'
			: 'https://js.squareupsandbox.com/v2/paymentform');
	}
}

export default init;
