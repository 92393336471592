import _ from 'underscore';

/**
 * This is a global mixin applied to every components on the app
 */
export default {

	created() {
		this._listeners = [];
	},

	beforeDestroy() {
		this._listeners.forEach(listener => {
			this.$root.$off(listener.event, listener.callback);
		});

		this._listeners = [];
	},

	methods: {

		addRootListener(event, callback, ignoreDuplicates = true) {
			this.setUpRootListeners([ { event, callback } ], ignoreDuplicates);
		},

		setUpRootListeners(listeners = [], ignoreDuplicates = true) {
			listeners.forEach(listener => {
				if (ignoreDuplicates && this._listenerExists(listener.event)) return;

				this.$root.$on(listener.event, listener.callback);
				this._listeners.push(listener);
			});
		},

		_listenerExists(eventName) {
			return _.findIndex(this._listeners, { event: eventName }) !== -1;
		}

	},
};
