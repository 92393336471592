<template>
	<div class="input-wrapper">
		<div class="input-row">
			<div class="input-title"><a href @click.prevent="open = !open" class="fa fa-question-circle" tabindex="-1"></a><slot name="title"></slot></div>
			<div class="input-box"><slot name="field"></slot></div>
			<div v-if="$slots.notice" class="input-notice"><slot name="notice"></slot></div>
		</div>
		<slide-up-down :active="open" :duration="350">
			<div class="input-row input-tooltip-slider">
				<div class="input-box static-text shrink-text">
					<slot name="tooltip"></slot>
				</div>
			</div>
		</slide-up-down>
	</div>
</template>

<script>

export default {

	data() {
		return {
			open: false,
		};
	},

};

</script>
