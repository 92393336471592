<template>
	<div class="mobile-menu-button">
		<button class="hamburger hamburger--spin-r" type="button" @click="toggle" :class="{ 'is-active': menuOpen }">
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
		</button>
	</div>
</template>

<script>

import { mapState } from 'vuex';
import { EventBus } from '@/eventBus';

export default {

	computed: mapState({
		menuOpen: state => state.layout.menuOpen
	}),

	methods: {

		toggle() {
			if (!this.menuOpen) {
				// We're opening the menu, let's close the curtain
				EventBus.$emit('closeCartCurtain');
			}
			this.$store.dispatch('toggleMenu');
		}
	}
};

</script>
