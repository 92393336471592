<template>
	<div v-if="hasErrors" class="alert alert-bad">
		<ul>
			<li v-for="error in errors" :key="`${ field }-${ error }`"><span class="fa fa-flag"></span>{{ text(error) }}</li>
		</ul>
	</div>
</template>

<script>

export default {

	props: [
		/**
		 * The vuelidate field ($v.<form>)
		 *
		 * @property {Object} form
		 */
		'form',

		/**
		 * The name of this field
		 *
		 * @property {String} field
		 */
		'field',

		/**
		 * An optional readable name
		 *
		 * @property {String} name
		 */
		'name',

		/**
		 * An optional formattable errorText. will replace %s with readableName
		 *
		 * @property {String} errorText
		 */
		'errorText',
	],

	name: 'FormFieldErrors',

	computed: {

		hasErrors() {
			return this.errors.length > 0;
		},

		errors() {
			if (!this.form || !this.form[ this.field ]) return [];
			return Object.keys(this.form[ this.field ].$params).filter(validation => !this.form[ this.field ][ validation ]);
		},

		readableName() {
			if (this.name && this.name.length > 0) return this.name;

			let field = this.field;

			if (field.substr(-3) === '_id') {
				field = field.substring(0, field.length-3);
			}

			field = field.replace('_', ' ' ).replace( /([A-Z])/g, " $1" );

			// Capitalize words
			return field.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
		}
	},

	methods: {

		text(key) {

			if (this.errorText) {
				return this.errorText.replace('%s', this.readableName);
			}

			switch (key) {
				case 'required':
					return `The ${ this.readableName } field is required`;
				case 'sameAs':
					return `The ${ this.readableName } does not match`;
				case 'email':
					return `The ${ this.readableName } field must be a valid email`;
				default:
					return `The ${ this.readableName } field is invalid`;
			}
		},

	},

};

</script>
