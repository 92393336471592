import Vue from 'vue';
import { LAYOUTS } from '../constants';

let NProgress;
if (process.client) {
	NProgress = require('nprogress');
}

export default {
	state: () => ({
		siteLoading: false,
		menuOpen: false,
		layout: LAYOUTS.APP,
		nav: null,
		title: 'Fine Grounds',
		toast: {
			active: false,
		},
	}),

	getters: {

		toast(state) {
			return state.toast;
		},

		hasToast(state) {
			return state.toast.active;
		},

	},

	mutations: {

		SET_LAYOUT(state, layout) {
			state.layout = layout;
		},

		SET_SITE_LOADING(state, isLoading) {
			state.siteLoading = isLoading;

			if (process.client) {
				if (isLoading) {
					NProgress.start();
					NProgress.set(0.1);
				} else {
					setTimeout(() => NProgress.done(), 5);
				}
			}
		},

		SET_NAV(state, nav) {
			state.nav = nav;
		},

		SET_TITLE(state, title) {
			state.title = title;
		},

		SET_MENU_OPEN(state) {
			state.menuOpen = true;
		},

		SET_MENU_CLOSED(state) {
			state.menuOpen = false;
		},

		ADD_TOAST(state, { toast }) {
			toast.active = true;
			state.toast = toast;
		},

		REMOVE_TOAST(state) {
			state.toast.active = false;
			clearInterval(state.toast.intRef);
		},

		UPDATE_TOAST_DURATION(state, { duration }) {
			Vue.set(state.toast, 'duration', state.toast.duration - duration);
		},

		SET_TOAST_DURATION(state, { duration}) {
			Vue.set(state.toast, 'duration', duration);
		},
	},

	actions: {

		setLayout({ commit }, layout = LAYOUTS.APP) {
			commit('SET_LAYOUT', layout);
		},

		setSiteLoading({ commit }, isLoading = true) {
			commit('SET_SITE_LOADING', isLoading);
		},

		setNav({ commit }, nav) {
			commit('SET_NAV', nav);
		},

		setTitle({ commit }, title) {
			commit('SET_TITLE', title);
		},

		toggleMenu({ dispatch, state }) {
			dispatch(state.menuOpen ? 'closeMenu' : 'openMenu');
		},

		openMenu({ commit }) {
			commit('SET_MENU_OPEN');
		},

		closeMenu({ commit }) {
			commit('SET_MENU_CLOSED');
		},

		addToast({ commit, dispatch }, toast = {}) {
			const duration = 7500;
			if (typeof toast === 'string') return dispatch('addToast', { message: toast });

			commit('REMOVE_TOAST');

			commit('ADD_TOAST', {
				toast: Object.assign({
					message: '',
					type: 'good',
					icon: 'fa-times',
					onClick: () => {
						commit('REMOVE_TOAST');
					},
					intRef: setInterval(() => {
						dispatch('updateToastDuration', { duration: 250 });
					}, 250),
					duration,
				}, toast),
			});
		},

		errorToast({ dispatch }, message) {
			dispatch('addToast', { message, type: 'bad' });
		},

		pauseToast({ state }) {
			if (state.toast) {
				clearInterval(state.toast);
			}
		},

		resumeToast({ state, dispatch }) {
			if (state.toast) {
				clearInterval(state.toast.intRef);
				state.toast.intRef = setInterval(() => {
					dispatch('updateToastDuration', { duration: 100 });
				}, 100);
			}
		},

		updateToastDuration({ commit, state }, { duration }) {
			if (state.toast) {
				if (state.toast.duration < duration) {
					// Close Toast
					commit('REMOVE_TOAST');
				} else {
					commit('UPDATE_TOAST_DURATION', { duration });
				}
			}
		}
	},
};
