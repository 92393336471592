<template>
	<span>
		<form-errors v-if="hasErrors" :errors="[ errorText ]"></form-errors>
		<button v-if="!loading" type="submit" class="submit-standard" :class="`${ type }-bg`">{{ text }}<span class="fal" :class="icon"></span></button>
		<button v-else type="submit" class="submit-standard neutral-bg" disabled>{{ processingText }}<span class="fal fa-spinner fa-spin"></span></button>
	</span>
</template>

<script>

export default {

	props: {
		loading: {
			type: Boolean,
			default: false,
		},

		text: {
			type: String,
			default: 'Submit Now',
		},

		icon: {
			type: String,
			default: 'fa-key',
		},

		processingText: {
			type: String,
			default: 'Processing',
		},

		errorText: {
			type: String,
			default: 'Unable to Process Request',
		},

		hasErrors: {
			type: Boolean,
			default: false,
		},

		type: {
			type: String,
			default: 'good',
		},
	},

};

</script>
