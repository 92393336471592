import Vue from 'vue';
import Router from 'vue-router';
import VueMeta from 'vue-meta';
import { LAYOUTS } from '../constants';

Vue.use(Router);
Vue.use(VueMeta);

/**
 * Meta values determine route-specific functionality. *Note* that if a meta value doesn't exist on the route, it will look upwards to parents for a match and take the closest parents matching meta value (if applicable)
 * Meta Route Values:
 * {String} title - the title of the page
 * {Boolean} guest - Whether or not this route is intended only for guests
 * {Boolean} app - Specifies that the user must be authenticated to view this page
 * {String} layout - The layout for this route. must be set to one of the values in the LAYOUTS constant
 * {Boolean|String} intendedOverride - a special value that indicates this page can't be redirected to after switching accounts. Bool false wil direct them to the default (dashboard), a string would be a replacement path to take htem to
 */

export default () => {
	return new Router({
		mode: 'history',
		base: process.env.BASE_URL,
		linkActiveClass: 'active',
		routes: [

			// ------------------------------------------------------------------------------------------------------ //
			/** Sales / Unauth Sections **/
			// ------------------------------------------------------------------------------------------------------ //


			{
				path: '/',
				name: 'homepage',
				meta: { title: 'Order Coffee Online', layout: LAYOUTS.APP, locate: true },
				component: () => import(/* webpackChunkName: "HomePage" */ '@/views/pages/sales/HomePage')
			},

			{
				path: '/about',
				name: 'about-us',
				meta: { title: 'About Us', layout: LAYOUTS.APP, locate: false },
				component: () => import(/* webpackChunkName: "AboutPage" */ '@/views/pages/sales/AboutPage')
			},

			{
				path: '/blog',
				name: 'blog',
				meta: { title: 'Blog', layout: LAYOUTS.APP, locate: false },
				component: () => import(/* webpackChunkName: "BlogPage" */ '@/views/pages/sales/BlogPage')
			},

			{
				path: '/blog/:slug',
				name: 'blog-article',
				meta: { title: 'Blog Article', layout: LAYOUTS.APP, locate: false },
				component: () => import(/* webpackChunkName: "BlogArticlePage" */ '@/views/pages/sales/BlogArticlePage')
			},

			{
				path: '/businesses',
				name: 'how-it-works',
				meta: { title: 'How It Works', layout: LAYOUTS.APP, locate: false },
				component: () => import(/* webpackChunkName: "BusinessSalesPage" */ '@/views/pages/sales/BusinessSalesPage')
			},

			{
				path: '/contact',
				name: 'contact-us',
				meta: { title: 'Contact US', layout: LAYOUTS.APP, locate: false },
				component: () => import(/* webpackChunkName: "ContactPage" */ '@/views/pages/sales/ContactPage')
			},

			{
				path: '/legal/privacy-policy',
				name: 'privacy-policy',
				meta: { title: 'Privacy Policy', layout: LAYOUTS.APP, locate: false },
				component: () => import(/* webpackChunkName: "PrivacyPolicyPage" */ '@/views/pages/sales/PrivacyPolicyPage')
			},

			{
				path: '/legal/terms-of-use',
				name: 'terms-of-use',
				meta: { title: 'Terms of Use', layout: LAYOUTS.APP, locate: false },
				component: () => import(/* webpackChunkName: "TermsOfUsePage" */ '@/views/pages/sales/TermsOfUsePage')
			},

			{
				path: '/login',
				name: 'login',
				redirect: '/login/customer',
			},

			{
				path: '/login/customer',
				name: 'customer-login',
				meta: { guest: true, title: 'Customer Login', layout: LAYOUTS.APP, locate: false },
				component: () => import(/* webpackChunkName: "CustomerLoginPage" */ '@/views/pages/auth/CustomerLoginPage')
			},

			{
				path: '/signup/customer',
				name: 'customer-signup',
				meta: { guest: true, title: 'Customer Sign Up', layout: LAYOUTS.APP, locate: false },
				component: () => import(/* webpackChunkName: "CustomerLoginPage" */ '@/views/pages/auth/CustomerSignUpPage')
			},

			{
				path: '/signup/customer/info',
				name: 'customer-signup-info',
				meta: { requiredAuth: 'customer', title: 'Welcome To Fine Grounds', locate: false, step2: true },
				component: () => import(/* webpackChunkName: "CustomerSignUpStepTwoPage" */ '@/views/pages/auth/CustomerSignupStepTwoPage')
			},

			{
				path: '/signup/business/info',
				name: 'business-signup-info',
				meta: { requiredAuth: 'business', title: 'Welcome To Fine Grounds', locate: false, step2: true },
				component: () => import(/* webpackChunkName: "BusinessSignUpStepTwoPage" */ '@/views/pages/auth/BusinessSignupStepTwoPage')
			},

			{
				path: '/login/business',
				name: 'business-login',
				meta: { guest: true, title: 'Login', layout: LAYOUTS.APP, locate: false },
				component: () => import(/* webpackChunkName: "BusinessLoginPage" */ '@/views/pages/auth/BusinessLoginPage')
			},

			{
				path: '/signup/business',
				name: 'business-signup',
				meta: { guest: true, title: 'Business Sign Up', layout: LAYOUTS.APP, locate: false },
				component: () => import(/* webpackChunkName: "BusinessSignupPage" */ '@/views/pages/auth/BusinessSignupPage')
			},

			{
				path: '/login/:userType/:driver/callback',
				name: 'customer-social-callback',
				props: true,
				meta: { title: 'Loading Response', nav: false, header: 'Back', locate: false },
				component: () => import(/* webpackChunkName: "SocialProviderReturnPage" */ '@/views/pages/auth/SocialProviderReturnPage'),
			},

			{
				path: '/forgot-password',
				name: 'forgot-password',
				props: true,
				meta: { guest: true, title: 'Forgot Password', locate: false },
				component: () => import(/* webpackChunkName: "ForgotPasswordPage" */ '@/views/pages/auth/ForgotPasswordPage'),
			},
			{
				path: '/password/reset/:token',
				name: 'reset-password',
				props: true,
				meta: { guest: true, title: 'Reset Password', locate: false },
				component: () => import(/* webpackChunkName: "ResetPasswordPage" */ '@/views/pages/auth/ResetPasswordPage'),
			},

			{
				path: '/account',
				meta: { requiredAuth: 'customer', layout: LAYOUTS.APP, locate: false },
				redirect: { name: 'order-history' },
				component: () => import(/* webpackChunkName: "AccountPage" */ '@/views/pages/customer/account/Account'),
				children: [
					{
						path: 'order-history',
						name: 'order-history',
						meta: { title: 'Order History', locate: false },
						component: () => import(/* webpackChunkName: "OrderHistoryPage" */ '@/views/pages/customer/order-history/OrderHistoryPage'),
					},
					{
						path: 'profile',
						name: 'profile-page',
						meta: { title: 'Profile', locate: false },
						component: () => import(/* webpackChunkName: "ProfilePage" */ '@/views/pages/customer/account/profile/ProfilePage')
					},
					{
						path: 'password',
						name: 'profile-password-page',
						meta: { title: 'Change Password', locate: false },
						component: () => import(/* webpackChunkName: "UpdatePasswordPage" */ '@/views/pages/customer/account/profile/UpdatePasswordPage')
					},
					{
						path: '/checkout/:orderId/complete',
						name: 'checkout-completed',
						meta: { requiredAuth: 'customer', title: 'Checkout Completed', layout: LAYOUTS.APP, locate: false },
						component: () => import(/* webpackChunkName: "OrderCompletedPage" */ '@/views/pages/customer/checkout/OrderCompletedPage'),
					},
				]
			},

			{
				path: '/checkout',
				name: 'checkout',
				meta: { requiredAuth: 'customer', title: 'Checkout', layout: LAYOUTS.APP, locate: false },
				component: () => import(/* webpackChunkName: "CheckoutPage" */ '@/views/pages/customer/checkout/CheckoutPage'),
			},

			{
				path: '/email-verified',
				name: 'email-verified',
				meta: { title: 'Email Verified', locate: false },
				component: () => import(/* webpackChunkName: "EmailVerifiedPage" */ '@/views/pages/customer/account/EmailVerifiedPage')
			},


			{
				path: '/browse',
				redirect: { name: 'browse-by-list' },
			},
			{
				path: '/browse/map',
				name: 'browse-by-map',
				meta: { layout: LAYOUTS.APP, title: 'Browse Locations by Map', locate: true },
				component: () => import(/* webpackChunkName: "BrowseLocationsByMapPage" */ '@/views/pages/customer/browse/BrowseLocationsWrapperPage')
			},
			{
				path: '/browse/list',
				name: 'browse-by-list',
				meta: { layout: LAYOUTS.APP, title: 'Browse Locations by List', locate: true },
				component: () => import(/* webpackChunkName: "BrowseLocationsByListPage" */ '@/views/pages/customer/browse/BrowseLocationsWrapperPage')
			},

			{
				path: '/browse/:businessSlug/locations/:locationId/items/:category?',
				name: 'browse-location-items',
				meta: { layout: LAYOUTS.APP, title: 'Browse Business Items', locate: false },
				component: () => import(/* webpackChunkName: "BrowseLocationItemsPage" */ '@/views/pages/customer/browse/BrowseLocationItemsPage')
			},

			{
				path: '/business',
				meta: { requiredAuth: 'business', layout: LAYOUTS.APP, locate: false },
				component: () => import(/* webpackChunkName: "BusinessSectionWrapper" */ '@/views/pages/business/Business'),
				children: [
					{
						path: 'welcome',
						name: 'business-welcome',
						meta: { title: 'Welcome To Fine Grounds', locate: false },
						component: () => import(/* webpackChunkName: "BusinessWelcomePage" */ '@/views/pages/business/BusinessWelcomePage')
					},
					{
						path: 'locations',
						name: 'business-locations',
						meta: { title: 'Manage Business Locations', locate: false },
						component: () => import(/* webpackChunkName: "BusinessLocationsPage" */ '@/views/pages/business/locations/BusinessLocationsPage')
					},
					{
						path: 'items',
						name: 'business-items',
						meta: { title: 'Manage Business Items', locate: false },
						component: () => import(/* webpackChunkName: "ManageItemsPage" */ '@/views/pages/business/items/ManageItemsPage')
					},
				],
			},

			{
				path: '/*',
				redirect: { name: 'homepage' },
			},
		]
	});
};
