<template>
	<modal name="start-fresh-cart" :clickToClose="true" transition="" :scrollable="true" :adaptive="true" height="auto" @before-open="beforeOpen">
		<div class="modal-content">
			<form action @submit.prevent="onSubmit">
				<div class="modal-title">Clear Existing Cart?<a href @click.prevent="$modal.hide('start-fresh-cart')" class="neutral-bg"><span class="fa fa-times-circle"></span></a></div>

				<div class="modal-text">
					You are attempting to add an item from a shop other than the one already in your cart. Would you like to clear your existing cart and start a new order?
				</div>

				<submit-button text="Replace my cart" icon="fa-arrow-right"></submit-button>
			</form>
		</div>
	</modal>
</template>

<script>

export default {

	data() {
		return {
			onAccept: null,
		};
	},

	methods: {

		beforeOpen(event) {
			this.onAccept = null;
			if (event.params) {
				this.onAccept = event.params.onAccept || null;
			}
		},

		onSubmit() {
			this.$store.dispatch('clearCart');
			if (this.onAccept) {
				this.onAccept();
			}

			this.$modal.hide('start-fresh-cart');
		},

	},

};

</script>
