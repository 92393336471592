import _ from 'underscore';
import hashItem from '../utils/hashItem';

class Item {

	constructor(item, selections = null) {
		this.item = { ...item };
		this.modifiers = _.indexBy((item.modifier_lists || []).map(list => list.modifiers).flat(), 'id');

		if (selections) {
			this.selections = selections;
		} else {
			this.setInitialSelections();
		}

		return new Proxy(this, {
			get(item, field) {
				if (field in item) return item[field];
				if (field in item.item) return item.item[field];

				return;
			}
		});
	}

	setInitialSelections() {
		this.selections = {
			id: this.item.id, // This is duplicated here so we can store the `selections` object for the cart.
			variantId: this.item.variants.length === 1 ? this.item.variants[0].id : null,
			modifiers: (this.item.modifier_lists || []).map(list => list.selections.defaults).filter(Boolean).flat(),
		};
	}

	get hash() {
		return hashItem(this);
	}

	setVariant(variantId) {
		this.selections.variantId = variantId;
	}

	get variant() {
		return _.findWhere(this.item.variants, { id: this.selections.variantId });
	}

	requiredModifiers() {
		return this.item.modifier_lists.filter(modifierList => modifierList.selections.min > 0);
	}

	selectedModifiers() {
		return this.selections.modifiers;
	}

	selectedModifierNames() {
		return this.selectedModifiers().map(modifierId => this.modifiers[ modifierId ].name);
	}


	outputPrice(quantity = 1) {
		let price = this.price(quantity);
		if (typeof price === 'number') {
			price = [ price ];
		}

		let output = '';
		if (price.length === 2) {
			output = 'Starting at ';
		}

		output += '$' + (price[0] / 100).toFixed(2);

		return output;

		// May 23 2021, Instead of showing price range, show "Starting At"
		// return [
		// 	...new Set(
		// 		price.map(amt => '$' + (amt / 100).toFixed(2))
		// 	)
		// ].join(' - ');
	}

	/**
	 *
	 * @returns {integer|[integer, integer]} - Returns a price or a price range depending on what's possible
	 * 											Amounts are in cents
	 */
	price(quantity = 1) {
		if (this.selections.variantId) {
			return this.calcPrice(quantity);
		}

		return this.calcPriceRange();
	}

	/**
	 * We can determine the current price
	 */
	calcPrice(quantity = 1) {
		let price = this.variant.pricing.price;

		this.selections.modifiers.forEach(modifierId => {
			price += (this.modifiers[ modifierId ] || {}).price || 0;
		});

		return price * quantity;

	}

	/**
	 * We can only determine a price range & this item cannot yet be added to your shopping cart
	 */
	calcPriceRange() {
		let variantPrices = this.item.variants.map(variant => variant.pricing.price).sort((a, b) => a - b);
		let range = { min: variantPrices[0], max: variantPrices[variantPrices.length - 1] };

		this.requiredModifiers().forEach(modifierList => {
			let modifierPrices = modifierList.modifiers.map(modifier => modifier.price).sort((a, b) => a - b);
			for (let i = 0; i < modifierList.selections.min; i++) {
				const nextLowestPrice = modifierPrices.shift();
				range.min += nextLowestPrice;
				range.max += nextLowestPrice;
			}
		});

		return [ range.min, range.max ];
	}

	primaryPhoto() {
		if (this.item.images && this.item.images.length > 0) {
			return _.findWhere(this.item.images, { is_primary: true }) || this.item.images[0];
		}

		return null;
	}

}

export default Item;
