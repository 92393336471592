export default {
	// Before new Vue is called: good place to define some routes or vuex modules
	async beforeStart({ app }) {

		if (app.$store.getters.isLoggedIn) {
			// await app.$store.dispatch('loadProjects');
		}

	},
};
