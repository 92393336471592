import Vue from 'vue';

Vue.filter('currency', function(value, decimals = 2) {
	if (typeof value !== 'number') {
		return value;
	}
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: decimals,
	});
	return formatter.format(value);
});

Vue.filter('cents', function(value, decimals = 2) {
	if (typeof value !== 'number') {
		return value;
	}
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: decimals,
	});
	return formatter.format(value / 100);
});
