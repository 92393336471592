import Vue from 'vue';
import Vuex from 'vuex';
import Storage from '@/storage/Storage';
import { EventBus } from '@/eventBus';
import getMeta from '@/utils/getMeta';
import getLayout from '@/utils/getLayout';

// Modules
import layout from './layout';
import auth from './auth';
import cart from './cart';

Vue.use(Vuex);

export default () => {
	return new Vuex.Store({
		state: () => ({
			platform: null,
			deviceInfo: {},
			intended: null,
		}),

		getters: {

			hasIntended(state) {
				return state.intended !== null;
			},

			intendedRoute(state) {
				return state.intended;
			},

			platform(state) {
				return state.platform;
			},

		},

		mutations: {

			SET_PLATFORM(state, platform) {
				state.platform = platform;
			},

			SET_DEVICE_INFO(state, deviceInfo) {
				state.deviceInfo = deviceInfo;
			},

			WIPE_ACCOUNT_DATA(state) {
				Storage(state.platform).remove('user');
				Storage(state.platform).remove('account');
				Storage(state.platform).remove('pendingInvitation');
			},

			SET_INTENDED(state, path) {
				Vue.set(state, 'intended', path);

				Storage(state.platform).set('intended', state.intended);
			},

			CLEAR_INTENDED(state) {
				Storage(state.platform).remove('intended');
				Vue.set(state, 'intended', null);
			},

		},

		actions: {

			onHttpRequest({ getters }) {
				if (getters.isLoggedIn) {
					// Put on-load stuff here. It'll load server side.
				}
			},

			onRouteChange({ dispatch, getters }, $route) {
				dispatch('setTitle', getMeta($route, 'title', 'Fine Grounds'));
				dispatch('setNav', getMeta($route, 'nav', null));
				dispatch('setLayout', getLayout($route, getters.isLoggedIn));
			},

			setPlatform({ commit }, platform) {
				commit('SET_PLATFORM', platform);
			},

			setDeviceInfo({ commit }, deviceInfo) {
				commit('SET_DEVICE_INFO', deviceInfo);
			},

			/**
			 * Clear out data loaded on the client that is account based - servers, projects, stories, deployments, etc
			 */
			wipeAccountData({ commit }) {
				commit('WIPE_ACCOUNT_DATA');
				commit('CLEAR_TOKEN');
			},

			setIntended({ commit }, path) {
				commit('SET_INTENDED', path);
			},

			clearIntended({ commit }) {
				commit('CLEAR_INTENDED');
			},

			goIntended({ getters }, defaultRoute = '/browse/list') {
				let path = defaultRoute;

				if (getters.hasIntended) {
					path = getters.intendedRoute;
				}

				EventBus.$emit('go', path);
			},

		},

		modules: {
			layout,
			auth,
			cart,
			// If you're adding a new module that contains account-specific data, update the WIPE_ACCOUNT_DATA mutation
		}
	});
};
