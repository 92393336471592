<template>
<div>
	<div class="cart" :class="{ active: cartOpen }">
		<div v-if="cartItems.length > 0">
			<div v-if="!loading" class="cart-buffer">
				<div class="cart-title">
					<div class="title">{{ cartSubtotal | cents }}</div>
					<div class="close"><a href @click.prevent="close" class="close-cart-button"><span class="fal fa-times-circle"></span></a></div>
				</div>

				<div class="cart-status">Your Order With <router-link v-if="cartLocation && cartLocation.id" @click="close" :to="`/browse/${ cartLocation.business.slug }/locations/${ cartLocation.id }/items`">{{ cartLocation.name }}</router-link></div>

				<div v-for="cartItem in cartItems" :key="`cart-${ cartItem.item.hash }`" class="cart-table">
					<div v-if="cartItem.item.primaryPhoto()" class="photo"><img :src="cartItem.item.primaryPhoto().url"></div>
					<div class="item">
						<h3>{{ cartItem.item.name }}</h3>
						<h4>{{ cartItem.item.price() | cents }}{{ cartItem.quantity > 1 ? ` Each` : ''}}</h4>
						<ul>
							<li>{{ cartItem.item.variant.name }}</li>
							<li v-for="modifierName in cartItem.item.selectedModifierNames()" :key="`${ cartItem.hash }-${ modifierName }`">{{ modifierName }}</li>
						</ul>
					</div>
					<div class="quantity">
						<select v-model="form[cartItem.item.hash]" @change="updateCart(true)">
							<option value="remove">Remove</option>
							<option v-for="index in Math.max(9,cartItem.quantity)" :key="`${cartItem.item.hash}-quantity-${index}`" :value="index">{{ index}}</option>
						</select>
					</div>
				</div>
				<a href="/checkout" @click.prevent="goCheckout" type="submit" class="submit-button">{{ !isLoggedIn ? 'Sign In To Checkout' : 'Checkout Now' }} <span class="fal fa-chevron-circle-right"></span></a>
				<div class="bottom-mobile-browser-buffer"></div>
			</div>
		</div>
		<div v-else class="container">
			<div class="cart-loader">
				<div class="cart-loader-container">
					<img v-if="loading" src="/images/logo.png" alt="">
					<b v-else>Nothing in your cart yet</b>

				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>

import { mapGetters } from 'vuex';
import CartItemsMixin from '@/mixins/CartItemsMixin';
import { EventBus } from '@/eventBus';

export default {

	name: 'CartCurtain',

	mixins: [ CartItemsMixin ],

	data() {
		return {
			cartOpen: false,
			form: {},
		};
	},

	computed: mapGetters(['cart', 'cartLocationId', 'cartLocation', 'isLoggedIn' ]),

	watch: {
		$route(to, from) {
			if (to.name !== from.name) {
				this.close();
			}
		},

		cartOpen: function(isOpen) {
			if (isOpen) {
				this.setCartForm();

				if (this.cartLocationId !== this.loadedLocationId) {
					this.loadItems(this.cartLocationId);
				} else if (this.cart.length !== this.cartItems.length) {
					this.refreshCart(this.itemsByVariant);
				}

			}
		},
	},

	mounted() {
		this.addRootListener('toggle-cart', () => this.cartOpen = !this.cartOpen);

		EventBus.$on('addedToCart', ({ customized }) => {
			if (this.cartOpen) {
				this.refreshCart(this.itemsByVariant);
				this.setCartForm();
			}

			setTimeout(() => this.cartOpen = true, customized ? 200 : 0);
		});

		EventBus.$on('closeCartCurtain', () => {
			this.close();
		});

		EventBus.$on('clearCart', () => {
			this.resetCart();
		});
	},

	methods: {

		setCartForm() {
			this.form = {};
			this.cart.forEach(cartItem => {
				this.form[cartItem.hash] = cartItem.quantity;
			});
		},

		updateCart(singleItem = false) {
			this.cartItems = this.cartItems.filter(cartItem => {
				return this.updateCartItem(cartItem);
			});

			if (!singleItem) {
				this.setCartForm();
			}
		},

		updateCartItem(cartItem) {
			const hash = cartItem.item.hash;
			const quantity = parseInt(this.form[hash], 10);

			if (this.form[hash] === 'remove' || quantity < 1 || this.form[hash] === '') {
				this.$store.dispatch('removeItem', hash);
				return false;
			}

			if (quantity >= 1 && quantity <= 9) {
				// Change Quantity
				this.$store.dispatch('updateQuantity', { hash, quantity });
				cartItem.quantity = quantity;

			}

			return true;
		},

		goCheckout() {
			this.close();
			if (this.isLoggedIn) {
				this.$router.push('/checkout');
			} else {
				this.$store.dispatch('addToast', 'You must sign up or log in');
				this.$store.dispatch('setIntended', '/checkout');
				this.$router.push('/login/customer');
			}

		},

		close() {
			this.cartOpen = false;
		},

	},



};

</script>
