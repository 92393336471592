/**
 * Retrieve the nearest meta value in the route chain
 *
 * @param route - the to/from route object
 * @param {string} name
 * @param {string|boolean||function} [defaultValue] The value to provide if cannot find meta value. Can be a callback
 * @returns {*}
 */
export default function getMeta(route = {}, name, defaultValue = undefined) {
	if (typeof route.meta[ name ] !== 'undefined') {
		return route.meta[ name ];
	}

	if (route.matched) {
		for (let match of route.matched.slice().reverse()) {
			if (typeof match.meta[ name ] !== 'undefined') {
				return match.meta[ name ];
			}
		}
	}

	if (typeof defaultValue === 'function') {
		return defaultValue();
	}

	return defaultValue;

}
