/*eslint no-empty:0 */

function decode (s) {
	return s.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);
}

function converter() {}

function get (req, key, json) {
	var jar = {};
	// To prevent the for loop in the first place assign an empty array
	// in case there are no cookies at all.
	var cookies = req.headers.cookie ? req.headers.cookie.split('; ') : [];
	var i = 0;

	for (; i < cookies.length; i++) {
		var parts = cookies[i].split('=');
		var cookie = parts.slice(1).join('=');

		if (!json && cookie.charAt(0) === '"') {
			cookie = cookie.slice(1, -1);
		}

		try {
			var name = decode(parts[0]);
			cookie = (converter.read || converter)(cookie, name) ||
				decode(cookie);

			if (json) {
				try {
					cookie = JSON.parse(cookie);
				} catch (e) {}
			}

			jar[name] = cookie;

			if (key === name) {
				break;
			}
		} catch (e) {}
	}

	return key ? jar[key] : jar;
}

export default {
	get(req, key) {
		return get(req, key, false /* read as raw */);
	},
	getJSON(req, key) {
		return get(req, key, true /* read as json */);
	}
};
