<template>
	<div :class="parentClass">
		<div :class="subClass"><span class="fal fa-spinner fa-spin"></span>{{ fullPage ? '' : text.toUpperCase() }}</div>
	</div>
</template>

<script>

export default {

	props: {
		text: {
			type: String,
			default: 'LOADING',
		},
		withInputContainer: {
			type: Boolean,
			default: true,
		},
		fullPage: {
			type: Boolean,
			default: false,
		},
	},

	computed: {

		parentClass() {
			let classes = [];

			if (this.fullPage) {
				classes.push('full-page-loader');
			}
			else if (this.withInputContainer) {
				classes.push('input-container');
			}

			return classes.join(' ');
		},

		subClass() {
			if (this.fullPage) {
				return 'full-page-loader-cell';
			}

			return 'input-loading';
		},
	}

};

</script>
