<template>
	<div v-show="toast.active" @mouseover="pause" @mouseout="resume" @click="handleClick" class="alert-bar active" :class="`alert-${ toast.type }`">
		<ul>
			<li>{{ toast.message }} <span class="fal fa-chevron-up"></span></li>
		</ul>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {

	computed: {

		...mapGetters([ 'toast' ]),

	},

	methods: {

		pause() {
			this.$store.dispatch('pauseToast');
		},

		resume() {
			this.$store.dispatch('resumeToast');
		},

		handleClick() {
			this.toast.onClick();
		},

	}
};

</script>
