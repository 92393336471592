import CookieStorageHelper from './CookieStorageHelper';
import StorageHelper from './StorageHelper';

function Storage(platform) {
	if (platform === null || platform === 'web' || process.server) {
		return CookieStorageHelper;
	}

	return StorageHelper;
}

export default Storage;
