<template>
	<div><input
		type="text"
		v-format-with-position
		v-model="inputVal"
		v-bind="$attrs"
		@input="handleInput"
		:data-position="position"
		:data-prev-value="prevValue"
		:class="{ 'default-slug': isTrackingBaseField }"
		@blur="onBlurHandler"
	><a href v-if="!isTrackingBaseField" @click.prevent="inputVal = slugify(baseField)" class="fa fa-undo"></a></div>
</template>

<script>

import _ from 'underscore';

export default {

	props: [ 'value', 'baseField' ],

	data() {
		return {
			inputVal: this.value || '',
			prevValue: this.value || '',
			position: 0,
		};
	},

	computed: {
		isTrackingBaseField() {
			return !this.baseField || this.inputVal === this.slugify(this.baseField);
		},
	},

	watch: {
		baseField(newValue, oldValue) {
			if (this.slugMatchesValue(oldValue)) {
				// We're automatically updating the slug based on changes to the baseField
				this.inputVal = this.slugify(newValue);
			}
		},

		value(val) {
			if (val !== this.inputVal) {
				this.inputVal = this.slugify(val);
			}
		},
	},

	directives: {
		formatWithPosition: {
			update(e) {
				const positionDiff = (e.dataset.prevValue.length - e.value.length) * -1;
				if (e.selectionEnd != e.dataset.position) {
					e.selectionEnd = parseInt(e.dataset.position, 10) + positionDiff;
				}
			}
		}
	},

	methods: {

		onBlurHandler() {
			this.inputVal = this.slugify(this.inputVal);
			this.$emit('input', this.inputVal);
		},

		handleInput(e) {
			this.prevValue = e.target.value;
			let targetValue = e.target.value;
			this.position = e.target.selectionStart;
			this.inputVal = this.slugify(targetValue, false);
			this.$emit('input', this.inputVal);
		},

		/**
		 * Whether or not they're actively using the auto-slugify function
		 *
		 * @returns {boolean}
		 */
		slugMatchesValue(value) {
			return this.slugify(value) == this.inputVal;
		},

		slugify(value = '', trim = true) {
			if (!_.isString(value)) {
				return '';
			}

			var slug = '';
			// Change to lower case
			var titleLower = value.toLowerCase();
			// Letter "e"
			slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
			// Letter "a"
			slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
			// Letter "o"
			slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
			// Letter "u"
			slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
			// Letter "d"
			slug = slug.replace(/đ/gi, 'd');
			if (trim === true) {
				// Trim the last whitespace or hyphen
				slug = slug.replace(/[\s-]*$/g, '');
			}
			// Change whitespace to "-"
			slug = slug.replace(/\s+/g, '-');
			// Remove all non-word chars
			slug = slug.replace(/[^\w-]+/g, '');
			// Replace multiple - with single -
			slug = slug.replace(/--+/g, '-');

			return slug;
		}
	}
};
</script>
