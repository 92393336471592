import getMeta from '@/utils/getMeta';
import { LAYOUTS } from "../constants";

export default function getLayout(to, isLoggedIn) {
	let layout = getMeta(to, 'layout', LAYOUTS.APP);
	if (typeof layout === 'function') {
		layout = layout(to, isLoggedIn);
	}

	return layout;
}
