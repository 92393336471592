<template>
	<div v-if="list.length" class="alert alert-bad">
		<ul>
			<li v-for="error in list" :key="`${ key }-${ error }`"><span class="fa fa-flag"></span>{{ error }}</li>
		</ul>
	</div>
</template>

<script>

import _ from 'underscore';

export default {

	props: [ 'errors' ],

	data() {
		return {
			key: _.uniqueId('errors'),
		};
	},

	computed: {

		list() {
			if (_.isArray(this.errors)) {
				return this.errors;
			}

			if (_.isObject(this.errors)) {
				return _.flatten(Object.values(this.errors));
			}

			return [];
		}
	}
};

</script>
