import Item from '@/services/Item';

export default {

	data() {
		return {
			loadedLocationId: null,
			loading: false,
			itemsByVariant: [],
			cartItems: [],
		};
	},

	computed: {

		cartSubtotal() {
			let total = 0;
			this.cartItems.forEach(cartItem => {
				total += (Math.abs(parseInt(cartItem.quantity, 10) || 0)) * cartItem.item.price();
			});

			return total;
		},

		cartSummary() {
			return this.cartItems.map(cartItem => ({
				name: cartItem.item.name,
				price: cartItem.item.price(),
				quantity: cartItem.quantity,
				variantName: cartItem.item.variant.name,
				modifiers: cartItem.item.selectedModifierNames(),
			}));
		},

	},

	methods: {

		async loadItems(locationId) {
			if (this.loading || !locationId) return;

			this.loading = true;

			try {
				const cart = await this.loadCartItems(locationId);

				this.itemsByVariant = cart.itemsByVariant;

				this.loadedLocationId = locationId;
			} catch (e) {
				this.$store.dispatch('errorToast', 'Unable to load cart');
			}

			this.loading = false;
		},

		async loadCartItems(locationId) {
			const { data } = await this.$api.get(`/browse/cart-items/locations/${ locationId }/items`);
			await this.$store.dispatch('loadCartLocation', locationId);
			let itemsByVariant = {};

			data.data.forEach(item => {
				item.variants.forEach(variant => {
					itemsByVariant[variant.id] = item;
				});
			});

			this.refreshCart(itemsByVariant);

			return {
				itemsByVariant,
			};
		},

		refreshCart(itemsByVariant) {
			let cartItems = [];
			let removedItems = [];

			this.cart.forEach(cartItem => {
				let item = itemsByVariant[ cartItem.selections.variantId ];
				if (item) {
					cartItems.push({
						item: new Item(item, cartItem.selections),
						quantity: cartItem.quantity,
					});
				} else {
					// Item or variant must no longer exist
					this.$store.dispatch('removeItem', cartItem.hash);
					removedItems.push(cartItem);
				}
			});

			if (removedItems.length > 0) {
				const count = removedItems.length;
				this.$store.dispatch(
					'addToast',
					count === 1
						? 'The item in your cart is no longer available'
						: `${ count } item${ count !== 1 ? 's' : '' } in your cart are no longer available`
				);
			}

			this.cartItems = cartItems;
		},

		resetCart() {
			this.loadedLocationId = null;
			this.loading = false;
			this.itemsByVariant = [];
			this.cartItems = [];
		},

	}

};
