<template>
	<div>
		<nav>
			<div class="logo"><router-link :to="isBusiness ? '/business/locations' : '/'"><img src="/images/logo.png" alt="Fine Grounds"></router-link></div>
			<menu-button v-if="!isBusiness"></menu-button>
			<div class="links">
				<router-link v-if="(!platform || platform === 'web') && !isBusiness" exact to="/">Home</router-link>
				<router-link v-if="!isBusiness" to="/browse">Browse</router-link>
				<router-link v-if="isLoggedIn && !isBusiness" to="/account/order-history">Orders</router-link>
				<router-link v-if="isLoggedIn && !isBusiness" to="/account/profile">Profile</router-link>

				<router-link v-if="!isLoggedIn" to="/signup/customer">Sign Up</router-link>
				<router-link v-if="!isLoggedIn" to="/login/customer">Log In</router-link>

				<a v-if="isLoggedIn && isBusiness" href="/business/locations">Manage</a>

				<a v-if="isLoggedIn && !isBusiness" href="/logout" @click.prevent="logout">Log Out</a>
			</div>
			<div class="buttons">
				<a v-if="isLoggedIn && isBusiness" href @click.prevent="logout">Log Out</a>
				<a v-if="!isBusiness && !itemsInCart" href @click.prevent="$root.$emit('toggle-cart')">Cart<span class="fal fa-mug"></span></a>
				<a v-if="!isBusiness && itemsInCart" href @click.prevent="$root.$emit('toggle-cart')" class="has-number">Cart<span class="number">{{ itemsInCart }}</span></a>
			</div>
		</nav>

		<!-- Main Menu -->
		<div class="mobile-menu">
			<div ref="mobileLinks" class="links">
				<router-link v-if="!platform || platform === 'web' && !isBusiness" exact to="/">Home</router-link>
				<router-link to="/browse">Browse</router-link>
				<router-link v-if="isLoggedIn && !isBusiness" to="/account/order-history">Orders</router-link>
				<router-link v-if="isLoggedIn && !isBusiness" to="/account/profile">Profile</router-link>

				<router-link v-if="!isLoggedIn" to="/signup/customer">Sign Up</router-link>
				<router-link v-if="!isLoggedIn" to="/login/customer">Log In</router-link>

				<a v-if="isLoggedIn && !isBusiness" href @click.prevent="logout">Log Out</a>
			</div>
		</div>

		<select-location v-if="$route.meta.locate"></select-location>

		<div class="main-container" :class="!$route.meta.locate ? 'no-location-bar' : ''">
			<toasts></toasts>
			<router-link :to="completedOrders.length === 1 ? `/checkout/${ completedOrders[0].orderId }/complete` : `/account/order-history`" v-show="completedOrders.length > 0" class="alert-bar active sticky-alert">
				<ul>
					<li>You have {{ completedOrders.length === 1 ? 'an open order' : 'open orders'}} <span class="fal fa-mug"></span></li>
				</ul>
			</router-link>
			<router-view></router-view>
		</div>

		<footer>
			<div class="footer-grid">
				<div class="links">
					<div class="headline">Customers</div>
					<router-link to="/">How It Works</router-link><br>
					<router-link to="/browse">Browse</router-link><br>
					<router-link to="/signup/customer">Sign Up</router-link><br>
					<router-link to="/login/customer">Log In</router-link><br>
				</div>
				<div class="links">
					<div class="headline">Businesses</div>
					<router-link to="/businesses">How It Works</router-link><br>
					<router-link to="/signup/business">Sign Up</router-link><br>
					<router-link to="/login/business">Log In</router-link><br>
				</div>
				<div class="links">
					<div class="headline">Company</div>
					<router-link to="/about">About Us</router-link><br>
					<router-link to="/contact">Contact Us</router-link><br>
					<router-link to="/blog">Blog</router-link><br>
				</div>
				<div class="links">
					<div class="headline">Legal</div>
					<router-link to="/legal/privacy-policy">Privacy Policy</router-link><br>
					<router-link to="/legal/terms-of-use">Terms of Use</router-link><br>
				</div>
			</div>
		</footer>
		<div class="credit">
			Developed By <a href="https://nightfox.digital" target="_blank" class="link">Night</a><a href="https://nightfox.studio" target="_blank" class="link">Fox</a>
			<router-link to="/about" class="floating-logo"></router-link>
		</div>
		<cart-curtain></cart-curtain>
		<modal-definitions></modal-definitions>
	</div>
</template>

<script>

import { mapGetters, mapState } from 'vuex';
import CartCurtain from '../pages/customer/cart/components/CartCurtain';
import ModalDefinitions from './modals/ModalDefinitions';
import SelectLocation from './components/SelectLocation';
import MenuButton from '../components/MenuButton';

export default {

	data() {
		return {
			cartOpen: false,
		};
	},

	computed: {

		...mapGetters([ 'isLoggedIn', 'isBusiness', 'accountType', 'hasCurrentLocation', 'currentLocation', 'cart', 'completedOrders', 'platform' ]),

		...mapState({
			menuOpen: state => state.layout.menuOpen,
		}),

		itemsInCart() {
			let numItems = 0;
			this.cart.forEach(cartItem => numItems += cartItem.quantity);

			return numItems;
		},
	},

	watch: {
		'$route': function() {
			this.$store.dispatch('closeMenu');
		},
	},

	mounted() {
		if (this.$refs.mobileLinks) {
			this.$refs.mobileLinks.addEventListener('click', (e) => {
				if (e.target && e.target.tagName === 'A') {
					this.closeMenu();
				}
			});
		}
	},

	methods: {

		openMenu() {
			this.$store.dispatch('openMenu');
		},

		closeMenu() {
			this.$store.dispatch('closeMenu');
		},

		logout() {
			this.$store.dispatch('logout');
			this.$nextTick(() => {
				this.$router.push('/login');
			});
		}

	},

	components: {
		MenuButton,
		ModalDefinitions,
		CartCurtain,
		SelectLocation,
	},

};
</script>
