import _ from 'underscore';
import { EventBus } from '@/eventBus';
import Vue from 'vue';
import moment from 'moment-timezone';

/**
 * Important: User can only checkout for a single locationId at a time. You cannot make purchases to multiple merchants.
 */
export default {
	state: () => ({

		/**
		 * var {Item[]} cart
		 */
		cart: [],

		locationId: null,

		location: {},

		completedOrders: [],
	}),

	getters: {

		cart(state) {
			return state.cart;
		},

		cartByItemId(state) {
			return _.indexBy(state.cart, 'selections.id');
		},

		cartByVariantId(state) {
			return _.indexBy(state.cart, 'selections.variantId');
		},

		cartLocationId(state) {
			return state.locationId;
		},

		cartLocation(state) {
			return state.location;
		},

		completedOrders(state, getters) {
			if (!getters.isLoggedIn) return [];
			return state.completedOrders.filter(completedOrder => {
				const hoursSinceOrdered = Math.abs(moment.duration(moment().diff(completedOrder.addedAt)).asHours());
				return completedOrder.userId === getters.currentUser.id && hoursSinceOrdered < 2;
			});
		},

	},

	mutations: {

		ADD_ITEM(state, { item, quantity = 1 }) {
			let entry = {
				hash: item.hash,
				selections: Object.freeze({
					variantId: item.selections.variantId,
					modifiers: [ ...item.selectedModifiers() ],
					id: item.id,
				}),
				quantity,
			};

			for (let i = 0; i < state.cart.length; i++) {
				if (item.hash === state.cart[i].hash) {
					entry.quantity += state.cart[i].quantity;
					Vue.set(state.cart, i, entry);
					return;
				}
			}

			state.cart.push(entry);
		},

		CHANGE_QUANTITY(state, { hash, quantity }) {
			state.cart = state.cart.map(cartItem => {
				if (cartItem.hash === hash) {
					cartItem.quantity = quantity;
				}

				return cartItem;
			});
		},

		REMOVE_ITEM(state, hash) {
			state.cart = state.cart.filter(cartItem => hash !== cartItem.hash);
		},

		SET_LOCATION_ID(state, locationId) {
			if (locationId != state.locationId) {
				state.cart = [];
				state.locationId = locationId;
			}

		},

		SET_LOCATION(state, location) {
			state.location = location;
		},

		SET_CART(state, { cart, locationId }) {
			state.cart = cart;
			state.locationId = locationId;
		},

		CLEAR_CART(state) {
			state.cart = [];
			state.locationId = null;
		},

		COMPLETE_ORDER(state, { orderId, userId, locationId }) {
			state.completedOrders.push({
				addedAt: moment(),
				orderId,
				userId,
				locationId,
			});
		},

		SET_COMPLETED_ORDERS(state, completedOrders) {
			state.completedOrders = completedOrders;
		},

		CLEAR_COMPLETED_ORDER(state, orderId) {
			state.completedOrders = state.completedOrders.filter(completedOrder => completedOrder.orderId !== orderId);
		},

	},

	actions: {

		async addToCart({ commit, dispatch }, { locationId, item, quantity = 1, customized = false }) {
			await dispatch('setLocation', locationId);
			commit('ADD_ITEM', { item, quantity });

			EventBus.$emit('addedToCart', {
				locationId, item, quantity, customized
			});
		},

		completeOrder({ commit }, { orderId, userId, locationId }) {
			commit('COMPLETE_ORDER', { orderId, userId, locationId });
		},

		clearCompletedOrder({ commit }, orderId) {
			commit('CLEAR_COMPLETED_ORDER', orderId);
		},

		updateQuantity({ commit }, { hash, quantity }) {
			commit('CHANGE_QUANTITY', { hash, quantity });
		},

		removeItem({ commit }, hash) {
			commit('REMOVE_ITEM', hash);
		},

		setCart({ commit }, { cart = [], locationId }) {
			commit('SET_CART', { cart, locationId });
		},

		async setLocation({ dispatch, commit }, locationId) {
			await dispatch('loadCartLocation', locationId);
			commit('SET_LOCATION_ID', locationId);
		},

		async loadCartLocation({ commit, state }, locationId) {
			if (state.locationId != locationId || !state.location || !state.location.id) {
				try {
					const { data } = await this.$api.get(`/browse/cart-items/locations/${locationId}/details`);
					commit('SET_LOCATION', data);
				} catch (e) {
					this.$rollbar.warn('Unable to load cart location', {e, locationId });
				}
			}
		},

		clearCart({ commit }) {
			commit('CLEAR_CART');
			EventBus.$emit('clearCart');
		},

	}
};
