<template>
	<modal name="location-details" :clickToClose="true" transition="" :scrollable="true" :adaptive="true" height="auto" @before-open="beforeOpen">
		<div class="modal-content">
			<div class="modal-title">{{ merchant.name }}<a href @click.prevent="$modal.hide('location-details')"><span class="fal fa-times-circle"></span></a></div>

			<GmapMap v-if="merchant" :zoom="10.75" :center="mapCenter" :options="mapOptions" class="modal-map">
				<GmapMarker :position="mapCenter" @click="mapInfoOpen = true" :options="{ maxWidth: 300, padding: 0 }">
					<GmapInfoWindow :position="mapCenter" :opened="mapInfoOpen" @closeclick="mapInfoOpen = null" :options="{ padding: 0 }">
						{{ merchant.name }}<br>
						{{ merchant.address1 }}<br>
						{{ merchant.city }}, {{ merchant.state }} {{ merchant.zip }}
					</GmapInfoWindow>
				</GmapMarker>
			</GmapMap>

			<div class="modal-info">
				<div class="phone">{{ merchant.phone_number }}</div>
				<div class="address">{{ merchant.address1 }}<br>{{ merchant.city }}, {{ merchant.state }} {{ merchant.zip }}</div>
				<a :href="`https://www.google.com/maps/dir/?api=1&destination=${ addressString }`" target="_blank">Directions from Google <span class="fal fa-directions"></span></a>
			</div>
		</div>
	</modal>
</template>

<script>

import mapOptions from '@/utils/maps';

export default {

	data() {
		return {
			loaded: false,
			failed: false,
			merchant: {},
			mapInfoOpen: false,
		};
	},

	computed: {

		mapOptions() {
			return {
				zoomControl: false,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: true,
				disableDefaultUi: false,
				styles: mapOptions.styles,
			};
		},

		mapCenter() {
			return {
				lat: parseFloat(this.merchant.latitude),
				lng: parseFloat(this.merchant.longitude),
			};
		},

		hasLocation() {
			return  (typeof this.merchant !== 'undefined');
		},

		addressString() {
			if (this.hasLocation) {
				const location = this.merchant;
				return `${ location.address1 } ${ location.address2 || '' }, ${ location.city } ${ location.state } ${ location.zip }`;
			}

			return '';
		},

	},

	methods: {

		beforeOpen(event) {
			this.merchant = {};
			if (event.params) {
				this.merchant = event.params.merchant || {};
				this.load();
			}

		},

		async load() {
			try {
				const { businessSlug, locationId } = this.$route.params;
				const [{ data: merchantData }] = await Promise.all([
					this.$api.get(`/browse/${businessSlug}/locations/${locationId}/details`),
				]);
				this.merchant = merchantData;
				this.loaded = true;
			} catch(e) {
				this.$store.dispatch('errorToast', 'Unable to load page');
			}
		},

	},

};

</script>
