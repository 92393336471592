<template>
	<div>
		<location-details-modal></location-details-modal>
		<location-closed-modal></location-closed-modal>
		<start-fresh-cart-modal></start-fresh-cart-modal>
	</div>
</template>

<script>

import LocationDetailsModal from './LocationDetailsModal';
import LocationClosedModal from './LocationClosedModal';
import StartFreshCartModal from './StartFreshCartModal';

export default {

	components: {
		LocationDetailsModal,
		LocationClosedModal,
		StartFreshCartModal,
	},

};

</script>
