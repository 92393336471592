export function hasCompletedSignup(store) {
	if (store.getters.isLoggedIn) {
		const user = store.getters.currentUser;
		if (store.getters.isBusiness && !user.email) {
			return false;
		} else if (store.getters.isCustomer && (!user.first_name || !user.last_name || !user.email)) {
			return false;
		}
	}

	return true;
}
