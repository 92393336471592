<template>
	<div id="app">
		<component :is="layout.layout"></component>
	</div>
</template>

<style lang="sass">
@import "../sass/app"
</style>

<script>

import { mapState, mapGetters } from 'vuex';
import { LAYOUTS } from '@/constants';

import AppLayout from '@/views/layouts/AppLayout';
import BusinessLayout from '@/views/layouts/BusinessLayout';

export default {

	metaInfo() {
		const layoutClass = {
			[LAYOUTS.APP]: 'app',
			[LAYOUTS.BUSINESS]: 'business',
		};

		let classes = [
			this.layout.theme,
			this.layout.menuOpen ? 'menu-open' : null,
			this.platform,
			layoutClass[ this.layout.layout ],
			this.curtainOpened ? 'curtain' : null,
			this.completedOrders.length ? 'active-alert-bar' : null,
			this.hasToast ? 'active-alert-bar' : null,
			this.$route.name,
		];

		return {
			title: this.layout.title,
			titleTemplate: '%s | Fine Grounds',
			bodyAttrs: {
				class: classes.filter(o => o).join(' '),
			},
		};
	},

	data() {
		return {
			curtainOpened: false,
			scrollY: 0,
		};
	},

	computed: {

		...mapGetters([ 'completedOrders', 'hasToast', 'platform' ]),

		...mapState({
			layout: state => state.layout,
		}),
	},

	mounted() {
		this.addRootListener('drawer-opened', () => {
			this.scrollY = window.scrollY;
			this.curtainOpened = true;
			document.body.style = `position:fixed; top:-${this.scrollY}px;`;
		});

		this.addRootListener('drawer-closed', () => {
			this.curtainOpened = false;
			this.$nextTick(() => {
				document.body.style = '';
				if (this.scrollY) {
					window.scrollTo(0, this.scrollY);
				}
				this.scrollY = null;
			});
		});

	},

	components: {
		AppLayout,
		BusinessLayout,
	},

};

</script>
