import { Plugins } from '@capacitor/core';
const { Device } = Plugins;
import Storage from '@/storage/Storage';

let createdUser;

export default {
	// Before new Vue is called: good place to define some routes or vuex modules
	async beforeCreate(context) {
		createdUser = null;
		let platform = null;

		if (!process.server) {
			const info = await Device.getInfo();
			platform = info.platform;
		}

		const storage = Storage(platform);

		try {
			let [user, userAuthToken, location, cart, completedOrders, intended] = await Promise.all([
				storage.get('user', context.req),
				storage.get('userAuthToken', context.req),
				storage.get('location', context.req),
				storage.get('cart', context.req),
				storage.get('completedOrders', context.req),
				storage.get('intended', context.req),
			]);

			// If we have a user we can log the user in.
			createdUser = user;
			if (user && user.user) {
				context.store.dispatch('login', user);
			}

			if (userAuthToken) {
				context.store.commit('SET_AUTH_TOKEN', userAuthToken);
			}

			if (intended) {
				context.store.dispatch('setIntended', intended);
			}

			if (location) {
				context.store.dispatch('setCurrentLocation', location);
			}

			if (cart) {
				context.store.dispatch('setCart', cart);
			}

			if (completedOrders) {
				context.store.commit('SET_COMPLETED_ORDERS', completedOrders);
			}
		} catch (e) {
			console.error('Unable to load data from storage on load', e.message);
		}
	},

	created({ store }) {
		if (createdUser && createdUser.user) {
			store.dispatch('getAccounts', createdUser.type === 'businesses');
		}
	}
};
