const Cookies = require('js-cookie');
import { cookie } from '@/utils';

class Cookie {
	static get(key, request = null) {
		if (request) {
			return cookie.get(request, key);
		}

		return Cookies ? Cookies.get(key) : null;
	}

	static getJSON(key, request = null) {
		if (request) {
			return cookie.getJSON(request, key);
		}

		return Cookies ? Cookies.getJSON(key) : null;
	}

	static set(key, value, options) {
		if (Cookies) {
			Cookies.set(key, value, { expires: options.expires || 360 });
			return true;
		}

		return false;
	}

	static remove(key) {
		if (Cookies) {
			Cookies.remove(key);
		}
	}

	/**
	 * Remove all cookies
	 */
	static clear() {
		if (Cookies) {
			Object.keys(Cookies.get()).forEach(function(cookie) {
				Cookies.remove(cookie);
			});
		}
	}
}

export default Cookie;
