export default {
	plugins: [
		'@uvue/core/plugins/asyncData',
		'@uvue/core/plugins/vuex',
		'@uvue/core/plugins/middlewares',
		'@/plugins/api',
		'@/plugins/initialize',
		'@/plugins/readCookiesMiddleware',
		'@/plugins/authenticationMiddleware',
		'@/plugins/broadcast',
		'@/plugins/eventListeners',
		'@/plugins/vue-js-modal',
		'@uvue/core/plugins/errorHandler',
		'@/plugins/routerGuards',
	],

	css: {
		normal: 'inline',
		vue: 'inline',
	},

};
