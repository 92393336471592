<template>
	<a href :key="`action-button-${ key }`" @click.prevent="onClick" :class="btnClass">
		{{ content }}<span class="fal" :class="processing ? 'fa-spinner fa-spin' : icon"></span>
	</a>
</template>

<script>

export default {

	props: {
		icon: {
			type: String,
			default: 'fa-exclamation-triangle',
		},

		text: {
			type: String,
			required: true
		},

		requiresConfirmation: {
			type: Boolean,
			default: false,
		},

		buttonClass: {
			type: String,
			default: 'neutral-bg',
		},

		confirmText: {
			type: String,
			default: 'Click here to confirm',
		},

		confirmedText: {
			type: String,
			default: 'Processing...',
		},
	},

	data() {
		return {
			confirming: false,
			performingAction: false,
			key: Math.random(),
		};
	},

	computed: {

		content() {
			if (this.performingAction) return this.confirmedText;

			if (this.confirming) return this.confirmText;

			return this.text;
		},

		processing() {
			return this.performingAction;
		},

		btnClass() {
			let classes = [];
			if (this.processing) {
				classes.push('neutral-bg');
				classes.push('disabled');
			} else {
				classes.push(this.buttonClass);
			}

			return classes.join(' ');
		},



	},

	methods: {

		onClick() {
			if (this.performingAction) return;

			if (this.requiresConfirmation && !this.confirming) {
				this.confirming = true;
				return;
			}

			this.confirming = false;
			this.performingAction = true;

			this.$emit('execute', () => {
				this.performingAction = false;
			});

			setTimeout(() => {
				this.performingAction = false;
			}, 5000);
		},
	},

};

</script>
