<template>
	<modal name="location-closed" :clickToClose="false" transition="" :scrollable="true" :adaptive="true" height="auto" @before-open="beforeOpen">
		<div class="modal-content">
			<form action @submit.prevent="onSubmit">
				<div class="modal-title">Location Closed</div>

				<div class="modal-text">
					{{ errorText }}
				</div>

				<submit-button text="I understand" icon="fa-times-circle"></submit-button>
			</form>
		</div>
	</modal>
</template>

<script>

import { mapGetters } from 'vuex';

export default {

	data() {
		return {
			locationId: null,
			onCheckout: false,
		};
	},

	methods: {

		beforeOpen(event) {
			this.onCheckout = false;
			if (event.params) {
				this.locationId = event.params.locationId || null;
				this.onCheckout = event.params.onCheckout || false;
			}
		},

		onSubmit() {
			if (this.hasItemsInCartForThisLocation) {
				this.$store.dispatch('clearCart');
			}
			this.$modal.hide('location-closed');
			this.$router.push('/browse/list');
		},

	},

	computed: {

		...mapGetters([ 'cart', 'cartLocationId', ]),

		hasItemsInCartForThisLocation() {
			return this.locationId == this.cartLocationId && this.cart.length > 0;
		},

		errorText() {
			let text = `Sorry! While you were ${ this.onCheckout ? 'checking out' : 'browsing' }, this location closed and is no longer accepting online orders.`;
			if (this.hasItemsInCartForThisLocation) {
				text += ' Click the button below to clear your cart and go find another shop.';
			}

			return text;
		}

	},

};

</script>
