<template>
	<div class="conjoin-input-containers">
		<div class="input-container">
			<div class="input-title"><slot name="title"></slot></div>
		</div>
		<div class="input-container">
			<div class="input-box"><textarea v-model="model" @input="onChange" v-bind="$attrs"></textarea></div>
		</div>
		<div class="input-container">
			<div class="input-box static-text shrink-text"><span :class="charactersUsedClass">{{ charactersUsed }}</span> / {{ max }} Char<span class="hide-on-mobile">acter</span>s Used</div>
		</div>
	</div>
</template>

<script>

export default {

	props: {
		value: {},
		max: {
			type: Number,
			default: 255,
		},
	},

	data() {
		return {
			model: this.value,
		};
	},

	computed: {

		charactersUsedClass() {
			return this.charactersUsed < this.max ? 'good-text' : 'bad-text';
		},

		charactersUsed() {
			if (!this.model) return 0;

			return this.model.toString().length;
		}

	},

	watch: {

		value: function() {
			this.model = this.value;
			this.onChange();
		},

	},

	mounted() {
		this.onChange();
	},

	methods: {

		onChange() {
			if (this.charactersUsed > this.max) {
				this.model = this.model.substring(0, this.max);
			}

			this.$emit('input', this.model);
		},

	},

};

</script>
