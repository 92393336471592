import axios from 'axios';
import moment from 'moment';
import getMeta from '@/utils/getMeta';

class API {

	constructor(context) {
		this.context = context;
		this.latest401Errors = [];
	}

	setApp(app) {
		this.app = app;
	}

	static baseUrl() {
		let base = process.env.VUE_APP_API_BASE_URL || 'http://localhost:8000/';
		// if (process.client) {
		// 	base = '/';
		// }
		// base = '/';
		let prefix = process.env.VUE_APP_API_URL_PREFIX || 'api/';

		return base + prefix;
	}

	instance() {
		// Singleton api client
		if (this.apiClient) return this.apiClient;

		// Create axios client
		this.apiClient = axios.create({
			baseURL: API.baseUrl(),
			withCredentials: true,
		});

		// Use request interceptors
		this.apiClient.interceptors.request.use(config => {
			// Get current token in cookies
			if (process.server) {
				config.headers.cookie = this.context.req.headers.cookie;
				config.headers['Referer'] = process.env.VUE_APP_FRONTEND_DOMAIN;
			}

			if (this.app.$store.state.auth.userAuthToken) {
				config.headers['Authorization'] = `Bearer ${this.app.$store.state.auth.userAuthToken}`;
			}

			return config;
		});

		// Use response interceptor
		this.apiClient.interceptors.response.use(
			this.handleSuccess.bind(this),
			this.handleError.bind(this)
		);

		return this.apiClient;
	}

	handleSuccess(response) {
		const authHeader = response.headers['x-user'];
		if (authHeader) {
			try {
				const auth = JSON.parse(process.server ? Buffer.from(authHeader, 'base64') : atob(authHeader));
				if (this.hasApp()) {
					this.latest401Errors = [];
					this.app.$store.dispatch('login', { user: auth.user, type: auth.guard, token: auth.token || null });
				}
			} catch (e) {
				console.log('failed to parse auth header', e.message);
			}
		}

		return Promise.resolve(response);
	}

	async handleError(error) {
		console.log('error!', error.message);
		// TODO (Low Priority) - This fails to handle login redirection on server load. It means if someone goes ot the site and is logged out, they'll see an auth page flash for a second
		if (!process.client) return;

		if (typeof error.response === 'undefined' || !error.response) {
			if (error.__CANCEL__) return Promise.reject(error);
			// TODO (Low Priority) - We do want to notify of network errors, but not if the user doesn't have the site visible. Implement VisibilityUI to only show this while app is visible
			// if (this.app) {
			// 	this.context.store.dispatch('addToast', { message: 'A network error has occurred. Please try again later.', type: 'bad' });
			// }
		}

		switch (error.response.status) {
			case 401:
				// Unauthenticated
				this.latest401Errors.push(moment());
				if (this.hasApp() && this.shouldLogoutFromUnauthenticated(error)) {
					this.context.store.dispatch('logout');
					this.context.store.dispatch('addToast', { message: 'You have been logged out', type: 'bad', unique: true });

					if (getMeta(this.app.$route, 'authRequired', false)) {
						this.app.$redirect('/login');
					}
				}

				break;
			case 419:
				// CSRF Failure. Re-fetch token & re-issue request
				try {
					await this.instance().get('/sanctum/csrf-cookie');
				} catch (e) {
					console.log('failed to get csrf cookie', e.message);
				}
				if (error.config) {
					return this.instance().request(error.config);
				}
				break;
			default:
				// window.app.$toastr('error', API.getErrorMessage(error));
				break;
		}

		return Promise.reject(error);
	}

	getErrorMessage(error) {
		if (typeof error.response === 'undefined') {
			return error.message;
		}

		const response = error.response;
		const data = response.data;

		if (response.status >= 500) {
			return 'Sorry! An internal error has occurred';
		}

		// Check for form validation errors
		if (data.hasOwnProperty('errors')) {
			let error = data.errors[ Object.keys(data.errors)[ 0 ] ];
			if (typeof error === 'string' ) {
				return error;
			}

			return error[ 0 ];
		}

		if (typeof data === 'object') {
			return data[ Object.keys(data)[ 0 ] ];
		}

		if (data.hasOwnProperty('message') && data.message.length > 0) {
			return data.message;
		}

		if (response.data === 404) {
			return 'Not Found';
		}

		if (typeof data === 'string') {
			return data;
		}

		return 'Sorry! An unknown error has occurred';
	}

	/**
	 * Return form errors if they exist, or fallback to getErrorMessage if no form errors can be found
	 *
	 * @param error
	 * @return {Object}
	 */
	getFormErrors(error) {
		if (error.response && error.response.data && error.response.data.errors) {
			return error.response.data.errors;
		}

		return { error: this.getErrorMessage(error) };
	}

	hasApp() {
		return !!(this.app);
	}

	shouldLogoutFromUnauthenticated() {
		// if (error.request) {
		// 	const autoLogoutRoutes = [
		// 		'/api/business-user',
		// 		'/api/user',
		// 	];
		//
		// 	const invalidRoutes = autoLogoutRoutes.filter(url => {
		// 		return (error.request.responseURL || '').indexOf(url) !== -1;
		// 	});
		//
		// 	if (invalidRoutes.length > 0) {
		// 		return true;
		// 	}
		// }

		const MAX_WITHIN_THRESHOLD = 2;
		const THRESHOLD_DURATION_SECONDS = 90;
		const thresholdTime = moment().subtract(THRESHOLD_DURATION_SECONDS, 'seconds');
		const recent401s = this.latest401Errors.filter(date => {
			return (date.isAfter(thresholdTime));
		});

		return recent401s.length > MAX_WITHIN_THRESHOLD;
	}

}

export default {

	createInstance(context) {
		return new Proxy(new API(context), {

			get(api, field) {
				if (field in api) return api[ field ];

				const instance = api.instance();

				if (field in instance) return instance[ field ];
			},

		});
	},

	beforeCreate(context, inject) {
		// Inject api everywhere
		this.instance = this.createInstance(context);
		inject('api', this.instance);
	},

	created({ app }) {
		this.instance.setApp(app);
	},

};
