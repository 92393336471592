import Pusher from 'pusher-js';

export default {

	beforeCreate({ store }, inject) {
		if (!process.client) {
			inject('broadcast', {});
			return;
		}

		// Inject broadcast everywhere
		const token = store.state.auth.token;
		const headers = {};
		if (token) {
			headers.Authorization = `Bearer ${ token }`;
		}

		if (!global.broadcast) {
			global.broadcast = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
				encrypted: true,
				cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
				auth: {
					headers
				},
				authEndpoint: process.env.VUE_APP_API_BASE_URL + 'broadcasting/auth',
				disableStats: true,
			});
		}

		inject('broadcaster', global.broadcast);
	},

};
