import Vue from 'vue';

import axios from 'axios';

// Vue Packages
import VueAxios from 'vue-axios';
import Vuelidate from 'vuelidate';
import Sortable from 'sortablejs';
import VueSocialAuth from 'vue-social-auth';
import VueTheMask from 'vue-the-mask';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import * as VueGoogleMaps from 'vue2-google-maps';

import 'vue-js-modal/dist/styles.css';

Vue.use(VueReCaptcha, {
	siteKey: process.env.VUE_APP_RECAPTCHA_KEY || '6LcSCyYaAAAAAEQcOxsWuHqzBGSG7tpqunf0TRLu',
});

Vue.use(VueAxios, axios);
Vue.use(VueSocialAuth, {
	providers: {
		square: {
			oauthType: '2.0',
			clientId: process.env.VUE_APP_SQUARE_CLIENT_ID,
			redirectUri: '/api/login/square/callback', // Your client app URL
			popupOptions: { width: 580, height: 400 },
		}
	}
});

Vue.use(Vuelidate);

Vue.directive('sortable', {
	inserted: function(el, binding) {
		el.sortable = Sortable.create(el, binding.value || {});
	},
	update: function(el, binding) {
		Object.keys(binding.value).forEach(key => {
			el.sortable.option(key, binding.value[ key ]);
		});
	},
});

Vue.use(VueTheMask);

Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyDoYN1IFrZORlAWHJ2RL_fCdiWr-IvKSnU',
		libraries: 'places', // This is required if you use the Autocomplete plugin
		// OR: libraries: 'places,drawing'
		// OR: libraries: 'places,drawing,visualization'
		// (as you require)

		//// If you want to set the version, you can do so:
		// v: '3.26',
	},

	//// If you intend to programmatically custom event listener code
	//// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
	//// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
	//// you might need to turn this on.
	// autobindAllEvents: false,

	//// If you want to manually install components, e.g.
	//// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
	//// Vue.component('GmapMarker', GmapMarker)
	//// then disable the following:
	// installComponents: true,
});
