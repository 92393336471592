import Vue from 'vue';
import NoSSR from 'vue-no-ssr';
import { EventBus } from '@/eventBus';

import createRouter from './router';
import createStore from './store';

import Rollbar from 'rollbar';

import App from './views/App.vue';

import Loading from './views/components/Loading';
import ActionButton from '@/views/components/ActionButton';
import SubmitButton from './views/components/SubmitButton';
import SlugField from './views/components/SlugField';
import TextareaCounterField from './views/components/TextareaCounterField';
import Toasts from './views/layouts/Toasts';
import Drawer from './views/layouts/Drawer';
import Date from './views/components/Date';
import RelativeDate from './views/components/RelativeDate';
import FormErrors from './views/components/FormErrors';
import FieldErrors from './views/components/FormFieldErrors';
import InputToolTip from './views/components/InputToolTip';
// import Selectize from './views/components/Selectize';
import SlideUpDown from './views/components/SlideUpDown';
import '@/filters';
import ListenerMixin from '@/mixins/ListenerMixin';
import StarRating from 'vue-star-rating';

import { Plugins } from '@capacitor/core';
const { SplashScreen } = Plugins;
// import './registerServiceWorker';

/**
 * Global ListenerMixin applied to every component
 * All components will now have access to root listeners
 */
Vue.mixin(ListenerMixin);

Vue.config.productionTip = false;

if (process.client) {
	require('./bootstrap.client');
}

Vue.component('no-ssr', NoSSR);
// Vue.component('selectize', Selectize);
Vue.component('star-rating', StarRating);
Vue.component('loading', Loading);
Vue.component('action-button', ActionButton);
Vue.component('submit-button', SubmitButton);
Vue.component('slug-field', SlugField);
Vue.component('textarea-counter-field', TextareaCounterField);
Vue.component('toasts', Toasts);
Vue.component('drawer', Drawer);
Vue.component('date', Date);
Vue.component('relative-date', RelativeDate);
Vue.component('form-errors', FormErrors);
Vue.component('field-errors', FieldErrors);
Vue.component('input-tooltip', InputToolTip);
Vue.component('slide-up-down', SlideUpDown);

export default () => {
	const store = createStore();
	const router = createRouter();

	// Set the Rollbar instance in the Vue prototype
	// before creating the first Vue instance.
	// This ensures it is available in the same way for every
	// instance in your app.
	Vue.prototype.$rollbar = new Rollbar({
		accessToken: '44cf6bc5535c4a2baec9f1c80cbeb79e',
		captureUncaught: true,
		captureUnhandledRejections: true,
		enabled: true,
		environment: process.env.NODE_ENV,
		client: {
			javascript: {
				source_map_enabled: true, // true by default

				// -- Add this into your configuration ---
				code_version: process.env.VUE_APP_GIT_HASH,
				// ---------------------------------------

				// Optionally have Rollbar guess which frames the error was
				// thrown from when the browser does not provide line
				// and column numbers.
				guess_uncaught_frames: true,
			},
		}
	});

	// If you have already set up a global error handler,
	// just add `vm.$rollbar.error(err)` to the top of it.
	// If not, this simple example will preserve the app’s existing
	// behavior while also reporting uncaught errors to Rollbar.
	Vue.config.errorHandler = (err, vm, info) => {
		vm.$rollbar.error(err);
		console.log('errorHandler info', info);
		// throw err; // rethrow
	};

	return new Vue({
		router,
		store,

		beforeMount() {
			if (this.$route.name === 'homepage' && ['android','ios'].includes(this.$store.getters.platform)) {
				// Mobile App users shouldn't see the sales page
				this.$router.replace('browse');
			}
		},

		mounted() {
			this.$store.dispatch('onRouteChange', this.$route);

			if (process.client) {
				window.onerror = (message, source, lineno, colno, error) => {
					this.$rollbar.error(message, error);
				};

				SplashScreen.hide();
			}
		},

		created() {
			this.startRootListeners();
			this.startRecurringDataSync();
		},

		beforeDestroy() {
			this.$broadcaster.disconnect();
		},

		methods: {

			startRootListeners() {
				EventBus.$on('go', path => {
					this.$router.push(path);
					this.$store.dispatch('clearIntended');
				});
			},

			/**
			 * Regularly sync data that is used on every page
			 */
			startRecurringDataSync() {
				if (process.client) {
					setInterval(() => this.syncAccountData(), 1000 * 240);
				}
			},

			syncAccountData() {
				if (store.getters.isLoggedIn) {
					store.dispatch('getAccounts', store.getters.isBusiness);
				}
			},

		},

		render: h => h(App),
	});
};
