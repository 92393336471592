export default {

	whenLoaded() {
		return new Promise((resolve, reject) => {
			if (typeof window !== 'undefined' && window.google) {
				return resolve();
			}

			let intRef = setInterval(() => {
				if (typeof window !== 'undefined' && window.google) {
					clearInterval(intRef);
					return resolve();
				}
			}, 25);

			setTimeout(() => {
				clearInterval(intRef);
				reject('Unable to load Google Maps');
			}, 30000);
		});
	},

	async getIcon() {
		await this.whenLoaded();

		return {
			url: '/images/map_marker.png',
			size: window.google.maps.Size(30, 38),
		};
	},

	styles: [
		{
			'featureType': 'administrative.country',
			'stylers': [
				{
					'visibility': 'off',
				},
			],
		},
		{
			'featureType': 'administrative.land_parcel',
			'stylers': [
				{
					'visibility': 'off',
				},
			],
		},
		{
			'featureType': 'administrative.locality',
			'stylers': [
				{
					'visibility': 'off',
				},
			],
		},
		{
			'featureType': 'poi',
			'stylers': [
				{
					'visibility': 'off',
				},
			],
		},
		{
			'featureType': 'transit.line',
			'stylers': [
				{
					'visibility': 'off',
				},
			],
		},
		{
			'featureType': 'transit.station',
			'stylers': [
				{
					'visibility': 'off',
				},
			],
		},
		{
			'featureType': 'transit.station.airport',
			'stylers': [
				{
					'visibility': 'off',
				},
			],
		},
		{
			'featureType': 'transit.station.bus',
			'stylers': [
				{
					'visibility': 'off',
				},
			],
		},
		{
			'featureType': 'transit.station.rail',
			'stylers': [
				{
					'visibility': 'off',
				},
			],
		},
		{
			'featureType': 'water',
			'stylers': [
				{
					'lightness': 45,
				},
			],
		},
	],
};
