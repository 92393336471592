<template>
	<div class="location-bar">
		<no-ssr>
			<div class="button">
				<a v-if="!gettingLocation" href @click.prevent="getCurrentLocation">Find Me<span class="fal fa-location"></span></a>
				<a v-if="gettingLocation" href @click.prevent="getCurrentLocation">Finding<span class="fal fa-spinner fa-spin"></span></a>
			</div>
			<div class="current"><span class="fa fa-map-marker"></span><gmap-autocomplete @place_changed="getAddressData" :placeholder="currentLocation.full_address ? currentLocation.full_address : `Enter Your City`" class="introInput"></gmap-autocomplete></div>
			<div class="options"><router-link to="/browse/list" :class="{ active: $route.name === 'browse-by-list' }">List<span class="fal fa-list"></span></router-link><router-link to="/browse/map" :class="{ active: $route.name === 'browse-by-map' }">Map<span class="fal fa-map"></span></router-link></div>
		</no-ssr>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import { Plugins } from '@capacitor/core';

const { Geolocation } = Plugins;

const getCurrentPosition = async () => {
	return await Geolocation.getCurrentPosition({ timeout: 30000, enableHighAccuracy: false });
};

export default {

	name: 'SelectLocation',

	data() {
		return {
			form: {
				address: '',
			},
			gettingLocation: false,
		};
	},

	computed: {

		...mapGetters(['hasCurrentLocation', 'currentLocation', 'isLoggedIn']),

	},

	mounted() {
		if (this.currentLocation.address) {
			this.form.address = this.currentLocation.address;
		}
	},

	methods: {

		getAddressData(address) {
			this.setLocation({
				latitude: address.geometry.location.lat(),
				longitude: address.geometry.location.lng(),
				timestamp: (new Date()).valueOf(),
				full_address: address.formatted_address,
				address: address.vicinity,
				accuracy: 'APPROXIMATE',
			});
		},

		async getCurrentLocation() {
			if (this.gettingLocation) return;

			this.gettingLocation = true;
			// navigator.geolocation.getCurrentPosition(async (position) => {
			try {
				const position = await getCurrentPosition();
				this.gettingLocation = false;
				const { address, full_address, accuracy } = await this.loadAddress(position.coords.latitude, position.coords.longitude);

				this.setLocation({
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
					timestamp: (new Date()).valueOf(),
					address,
					full_address,
					accuracy,
				});

			} catch (e) {
				this.gettingLocation = false;
				return this.$store.dispatch('addToast', { message: 'Unable to get your location: ' + e.message });
			}
		},

		async loadAddress(latitude, longitude) {
			const { data } = await this.$api.post('/coordinates/convert', { latitude, longitude });
			if (data.address) {
				return data;
			}
		},

		clearLocation() {
			this.$store.dispatch('clearCurrentLocation');
		},

		setLocation(location) {
			this.$store.dispatch('setCurrentLocation', location);

			if (this.isLoggedIn) {
				this.$api.post('/user/location', location);
			}

			this.$root.$emit('location-changed', location);

			if (!['browse-by-map', 'browse-by-list'].includes(this.$route.name)) {
				// Already on browse, let the location-changed event handle updates
				this.$router.push('/browse/list');
			}
		},

	},

};

</script>
