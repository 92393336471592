import Vue from 'vue';

export default {
	state: () => ({
		user: {},
		location: {
			latitude: null,
			longitude: null,
			city: null,
			address: null,
			timestamp: null,

		},
		userType: null, // users | businesses
		userAuthToken: null, // API Auth token (if using on mobile app)
		pendingInvitation: null,
	}),

	getters: {

		currentUser(state) {
			return (state.user && state.user.id) ? state.user : {};
		},

		currentLocation(state) {
			return state.location;
		},

		hasCurrentLocation({ location }) {
			return location && location.latitude && location.longitude;
		},

		currentAccount(state) {
			return (state.user && state.user.id) ? state.user : {};
		},

		accountType(state) {
			return state.userType;
		},

		isLoggedIn(state) {
			return !!(state.user && state.user.id);
		},

		isCustomer(state) {
			return !!(state.user && state.user.id) && (['user','users'].includes(state.userType));
		},

		isBusiness(state) {
			return !!(state.user && state.user.id) && (['business','businesses'].includes(state.userType));
		},

		token(state) {
			return state.token;
		},

		hasPendingInvitation(state) {
			return !!state.pendingInvitation && !!state.pendingInvitation.responseUrl;
		},

		pendingInvitation(state) {
			return state.pendingInvitation;
		},

		isEmailVerified(state) {
			return !!state.user.email_verified_at;
		},

	},

	mutations: {

		CLEAR_TOKEN(state) {
			Vue.set(state, 'userAuthToken', null);
		},

		SET_USER(state, user) {
			Vue.set(state, 'user', user.user);
			Vue.set(state, 'userType', user.type);

		},

		SET_AUTH_TOKEN(state, authToken) {
			Vue.set(state, 'userAuthToken', authToken);
		},

		SET_CURRENT_LOCATION(state, location) {
			Vue.set(state, 'location', location);
		},

		CLEAR_CURRENT_LOCATION(state) {
			Vue.set(state, 'location', {
				latitude: null,
				longitude: null,
				city: null,
				address: null,
				timestamp: null,

			});
		},

		SET_CURRENT_ACCOUNT(state, accountId) {
			Vue.set(state, 'currentAccountId', accountId);
		},

		LOGOUT(state) {
			Vue.set(state, 'user', {});
			Vue.set(state, 'userType', null);
			Vue.set(state, 'userAuthToken', null);
		},

		SET_PENDING_INVITATION(state, pendingInvite) {
			state.pendingInvitation = pendingInvite;
		},

		CLEAR_PENDING_INVITATION(state) {
			state.pendingInvitation = null;
		},

	},

	actions: {

		// eslint-disable-next-line no-unused-vars
		async getAccounts({ dispatch }, isBusiness = false) {
			try {
				const response = await this.$api.get(isBusiness ? '/business-user' : '/user');
				const data = response.data;
				if (data && data.data) {
					dispatch('setUser', { user: data.data, type: isBusiness ? 'businesses' : data.data.account_type });
				}
			} catch (e) {
				this.$rollbar.warn('Cannot load an account', e);
				if (process.server) {
					dispatch('logout');
				}
			}
		},

		/**
		 *
		 * @param commit
		 * @param user { user, type, token }
		 */
		setUser({ commit }, user) {
			commit('SET_USER', user);
			if (user.token) {
				commit('SET_AUTH_TOKEN', user.token);
			}
		},

		setCurrentLocation({ commit }, location) {
			commit('SET_CURRENT_LOCATION', location);
		},

		clearCurrentLocation({ commit }) {
			commit('CLEAR_CURRENT_LOCATION');
		},

		// Meta Action - Call other actions

		/**
		 *
		 * @param dispatch
		 * @param user { user, type, token?, implicitToken?: boolean }
		 */
		login({ dispatch }, user) {
			dispatch('setUser', user);
		},

		logout({ commit, dispatch }) {
			dispatch('wipeAccountData');
			commit('LOGOUT');
		},

		setPendingInvitation({ commit }, pendingInvite) {
			commit('SET_PENDING_INVITATION', pendingInvite);
		},

		async loadPendingAuth({ commit }, pendingInvitation) {
			commit('SET_PENDING_INVITATION', pendingInvitation);
		},

		accountUpdated({ commit, getters }, account) {
			commit('SET_ACCOUNT', account);
			if (getters.currentAccount.id === account.id) {
				commit('SET_CURRENT_ACCOUNT', account.id);
			}
		},

	},
};
