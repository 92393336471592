function hashCode(string) {
	var hash = 0, i, chr;
	for (i = 0; i < string.length; i++) {
		chr = string.charCodeAt(i);
		hash = ((hash << 5) - hash) + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
}

/**
 *
 * @param {Item} item
 */
export default function hashItem(item) {
	let hash = `${item.id}${item.selections.variantId}`;
	let modifiers = [ ...item.selectedModifiers() ];
	modifiers.sort();
	hash += modifiers.join('');

	return hashCode(hash);
}
